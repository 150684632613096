import { Box, Container, IconButton, Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Logo from "../../assets/images/logoribeira.png"; // Importe o logo da sua empresa
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <Box sx={{ bgcolor: "#444", color: "#fff", py: 3, height: "auto" }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ width: "200px", height: "auto", marginBottom: "20px" }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
              flexWrap: "wrap",
              marginBottom: "20px",
            }}
          >
            <Link to="Início" smooth={true} duration={1000}>
              <Button  sx={{color:'#fff', "&:hover": { backgroundColor: "orange" } }}>Início</Button>
            </Link>
            <Link to="quemsomos" smooth={true} duration={1000}>
              <Button  sx={{color:'#fff', "&:hover": { backgroundColor: "orange" } }}>Quem somos</Button>
            </Link>
            <Link to="projetos" smooth={true} duration={1000}>
              <Button  sx={{color:'#fff', "&:hover": { backgroundColor: "orange" } }}>Projetos</Button>
            </Link>
            <Link to="servicos" smooth={true} duration={1000}>
              <Button  sx={{color:'#fff', "&:hover": { backgroundColor: "orange" } }}>Serviços</Button>
            </Link>
            <Link to="atendimento" smooth={true} duration={1000}>
              <Button  sx={{color:'#fff', "&:hover": { backgroundColor: "orange" } }}>Atendimento</Button>
            </Link>
            <Link to="contato" smooth={true} duration={1000}>
              <Button  sx={{color:'#fff', "&:hover": { backgroundColor: "orange" } }}>Contato</Button>
            </Link>
            <Link to="localizacao" smooth={true} duration={1000}>
              <Button  sx={{color:'#fff', "&:hover": { backgroundColor: "orange" } }}>Localização</Button>
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Centraliza os ícones
              flexWrap: "wrap", // Permite que os ícones se ajustem em telas menores
              width: "100%", // Ajusta a largura para 100%
            }}
          >
            <a href="https://www.facebook.com/ribeiradiesel" target="_blank" rel="noopener noreferrer">
              <IconButton  aria-label="Facebook">
                <FacebookIcon sx={{ color: "#fff" }} />
              </IconButton>
            </a>
            <a href="https://www.instagram.com/ribeiradiesel" target="_blank" rel="noopener noreferrer">
              <IconButton  aria-label="Instagram">
                <InstagramIcon sx={{ color: "#fff" }} />
              </IconButton>
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

import React, { useState, useEffect, useRef } from "react";
import { Slider, IconButton, Box } from "@mui/material";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import video from "../../assets/video/institucional.mp4";
import "./style.css";
import { useMediaQuery } from "@mui/material";

const VideoComponent: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [muted, setMuted] = useState<boolean>(true);
  const [volume, setVolume] = useState<number>(0.5);
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleVolumeChange = (event: Event, newValue: number | number[]) => {
    setVolume(newValue as number);
    if (videoRef.current) {
      videoRef.current.volume = newValue as number;
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = muted;
    }
  }, [muted]);

  return (
    <div className="chev-down">
      <div className="video">
        <video ref={videoRef} autoPlay loop preload="metadata" className={isMobile ? "video-mobile" : "video-desktop"}>
          <source src={video} type="video/mp4" />
          Seu navegador não suporta o elemento de vídeo.
        </video>
        <Box
          sx={{
            position: "absolute",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            maxWidth: "100%",
            width: isMobile ? "90%" : "70%",
            display: "flex",
            justifyContent: "space-between",
            px: 2,
            alignItems: "center",
          }}
        >
          <Slider
            aria-label="Controle de volume"
            value={volume}
            onChange={handleVolumeChange}
            min={0}
            max={1}
            step={0.01}
            sx={{ width: { xs: 100, md: 200 }, color: "white" }}
          />
          <IconButton
            sx={{
              animation: "float 1.2s ease-in-out infinite",
              color: "white",
            }}
            aria-label="Mostrar opções"
          >
            <KeyboardArrowDownIcon sx={{ fontSize: isMobile ? 40 : 60 }} />
          </IconButton>
          <IconButton
            onClick={() => setMuted(!muted)}
            sx={{ color: "white" }}
            aria-label={muted ? "Ativar som" : "Desativar som"}
          >
            {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </IconButton>
        </Box>
      </div>
    </div>
  );
};

export default VideoComponent;

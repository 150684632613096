import React from "react";
import { Box, Typography, Container, Grid, Divider } from "@mui/material";
import { Fade } from "react-awesome-reveal"; // Importando a biblioteca
import teamImage from "../../assets/images/mecanico.jpg"; // Importando a imagem

const AboutUs = () => {
  return (
    <Box id="quemsomos">
      <Box
        sx={{
          width: "100%",
          height: "auto",
          position: "relative",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ py: { xs: 5, md: 8 }, mb: { xs: 0, md: 0 } }}
        >
          <Box
            sx={{
              textAlign: "center",
              height: "auto",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              padding: { xs: 2, md: 4 }, // Adapte o espaçamento interno para diferentes tamanhos de tela
              borderRadius: 8,
            }}
          >
            <Box sx={{ textAlign: "center", mb: 5 }}>
              <Typography variant="h3" component="h2" gutterBottom>
                Quem Somos
              </Typography>
              <Typography variant="subtitle1" component="p">
                Conheça mais sobre nossa equipe e missão.
              </Typography>
            </Box>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={5}>
                <Fade cascade damping={0.5}>
                  <Box
                    component="img"
                    sx={{
                      height: "auto",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: 8,
                    }}
                    alt="Foto da equipe"
                    src={teamImage}
                  />
                </Fade>
              </Grid>
              <Grid item xs={12} md={7}>
                <Fade cascade damping={0.5}>
                  <Typography variant="h3" component="h2" gutterBottom>
                    Ribeira Reparadora De Bombas Diesel Ltda.
                  </Typography>
                  <Box sx={{ mb: 4 }}>
                    <Typography variant="body1">
                      Manutenção no sistema de injeção a diesel: Eficiência e Qualidade

                      Oferecemos um dos mais eficientes serviços de reparação de Bombas e Bicos Injetores do mercado. Nosso laboratório climatizado a 20ºC, temperatura recomendada pelas normas ISO, garante que as reparações sejam realizadas em conformidade com os padrões dos fabricantes.
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1">
                      A importância da Bomba Injetora:

                      A Bomba Injetora é um dos componentes mais importantes do sistema de alimentação dos motores diesel. Ela é responsável por injetar o combustível no motor para que ocorra a combustão, trabalhando em conjunto com o regulador de rotação.
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1">
                      Nosso processo de reparação:

                      A Bomba é regulada manualmente por profissionais especializados e com o auxílio de ferramentas específicas. Diversos testes são realizados em uma bancada de testes apropriada.
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1">
                      Benefícios da reparação:

                      Funcionamento perfeito do motor;
                      Respeito às leis de emissão de poluentes;
                      Desempenho e consumo de acordo com as especificações do fabricante.
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="body1">
                      Confie na nossa experiência e expertise para garantir a qualidade e a eficiência no reparo do sistema de injeção a diesel.
                      Para mais informações, entre em contato conosco.
                    </Typography>
                  </Box>
                </Fade>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUs;

import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import api from "../../services/api";
import apiEmail from "../../services/api-email";

const Contact = () => {
  // Estados para os campos do formulário
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    whatsapp: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  // Função para atualizar os estados com base nos inputs
  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Função para enviar os dados do formulário
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://api.ribeiradiesel.swiftsoft.com.br/email/send-mail.php', formData);

      console.log('Resposta do servidor:', response);

     
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Erro ao enviar mensagem:', error.response?.data || error.message);
        alert(`Erro ao enviar mensagem: ${error.response?.data?.message || error.message}. Tente novamente.`);
      } else {
        console.error('Erro desconhecido ao enviar mensagem:', error);
        alert('Erro desconhecido ao enviar mensagem. Tente novamente.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box id="contato">
      <Container maxWidth="lg" sx={{ py: { xs: 5, md: 8 }, mb: { xs: 5, md: 10 } }}>
        <Box
          sx={{
            textAlign: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            padding: { xs: 2, md: 4 },
            borderRadius: 8,
          }}
        >
          <Typography variant="h3" component="h2" gutterBottom>
            Ribeira Reparadora de Bombas Diesel
          </Typography>
          <Typography variant="subtitle1" component="p" sx={{ mb: 3 }}>
            Especialização em reparação de bombas diesel desde 1988.
          </Typography>
          <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nome"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Empresa"
                variant="outlined"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="E-mail"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="WhatsApp"
                variant="outlined"
                name="whatsapp"
                value={formData.whatsapp}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Mensagem"
                variant="outlined"
                multiline
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  backgroundColor: "#8BC34A",
                  "&:hover": {
                    backgroundColor: "#4CAF50",
                  },
                }}
              >
                {loading ? 'Enviando...' : 'Enviar'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;

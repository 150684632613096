import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Fade } from "react-awesome-reveal";
import image1 from "../../assets/images/image1.jpg";
import image2 from "../../assets/images/image2.jpg";

const CustomerService = () => {
  return (
    <Box id="atendimento">
      <Box
        sx={{
          width: "100%",
          height: "auto",
          bgcolor: "#444",
          color: "#fff",
          py: { xs: 4, md: 6 }, // Ajustando o padding vertical
          px: 2, // Ajustando o padding horizontal
          position: "relative",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{ py: { xs: 4, md: 10 }, mb: { xs: 4, md: 0 } }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h3" component="h2" gutterBottom>
              Atendimento
            </Typography>
            <Typography
              variant="subtitle1"
              component="h3"
              sx={{ mt: 4, mb: 6 }}
            >
              Nosso compromisso é com a sua satisfação. Por isso, temos uma
              equipe de atendimento pronta para ajudá-lo. Estamos sempre à
              disposição para garantir a melhor experiência para você!
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Fade cascade damping={0.5}>
                  <Card>
                    <CardMedia
                      component="img"
                      sx={{
                        height: { xs: "auto", md: "50vh" },
                        width: "100%",
                        objectFit: "cover",
                      }}
                      image={image1}
                    />
                    <CardContent>
                      <Typography variant="body1" component="p">
                      Nossos atendentes estão à sua disposição de segunda a sexta, das 8h às 11h40 e das 13h às 18h. Estamos prontos para esclarecer suas dúvidas e ouvir suas sugestões.
                      </Typography>
                    </CardContent>
                  </Card>
                </Fade>
              </Grid>
              <Grid item xs={12} md={6}>
                <Fade cascade damping={0.5}>
                  <Card>
                    <CardMedia
                      component="img"
                      sx={{
                        height: { xs: "auto", md: "50vh" },
                        width: "100%",
                        objectFit: "cover",
                      }}
                      image={image2}
                    />
                    <CardContent>
                      <Typography variant="body1" component="p">
                      Além disso, disponibilizamos uma seção de Perguntas Frequentes em nosso site, onde você pode encontrar respostas para as dúvidas mais comuns de nossos clientes.
                      </Typography>
                    </CardContent>
                  </Card>
                </Fade>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CustomerService;

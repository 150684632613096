// Em Home.js ou Home.jsx

import Header from "../../components/Header";
import Main from "../../components/Main";
import VideoComponent from "../../components/VideoComponent";
import Whatsapp from "../../components/WhatsApp";
import AboutUs from "../../components/AboutUs";
import Projects from "../../components/Projects";
import Services from "../../components/ServicesComponent";
import CustomerService from "../../components/CustomerService";
import Contact from "../../components/Contact";
import MyMapComponent from "../../components/MyMapComponent";
import Footer from "../../components/Footer";

export function Home() {
  return (
    <>
      <Main>
        <Header />
        <VideoComponent />
        <Whatsapp />
        <AboutUs />
        <Projects />
        <Services />
        <CustomerService />
        <Contact />
        <MyMapComponent />
        <Footer />
        {/* Você pode adicionar mais componentes aqui conforme necessário */}
      </Main>
    </>
  );
}

import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton } from "@mui/material";

const Whatsapp: React.FC = () => {
  const mensagem = encodeURIComponent(
    "Olá, venho através do site e gostaria de mais informações sobre os serviços da Ribeira Diesel!"
  );
  const numeroTelefone = "5541984768377";
  const linkWhatsapp = `https://wa.me/${numeroTelefone}?text=${mensagem}`;

  return (
    <a
      href={linkWhatsapp}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <IconButton
        sx={{
          position: "fixed",
          right: 20,
          bottom: 80, // Adicionado para fixar o botão na parte inferior
          backgroundColor: "#25d366",
          color: "white",
          "&:hover": {
            backgroundColor: "#1ebe5f",
          },
          animation: "shake 0.82s cubic-bezier(.36,.07,.19,.97) both infinite",
          transform: "translate3d(0, 0, 0)",
          "@keyframes shake": {
            "10%, 90%": { transform: "rotate(-2deg)" },
            "20%, 80%": { transform: "rotate(4deg)" },
            "30%, 50%, 70%": { transform: "rotate(-8deg)" },
            "40%, 60%": { transform: "rotate(8deg)" },
          },
          height: 56,
          width: 56,
          zIndex: 9998,
        }}
      >
        <WhatsAppIcon sx={{ fontSize: "2rem" }} />
      </IconButton>
    </a>
  );
};

export default Whatsapp;

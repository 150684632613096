/* eslint-disable jsx-a11y/iframe-has-title */
import { Box } from "@mui/material";

const MapComponent = () => {
  return (
    <Box id="localizacao">
      <Box
        sx={{
          position: "relative",
          paddingBottom: "40%", /* Ajuste este valor para alterar a altura */
          height: 0,
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.34682088324!2d-49.18507602461288!3d-25.359690377606835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce8ead41fb34d%3A0x1e0512d552e45942!2sAv.%20Esp%C3%ADrito%20Santo%2C%20533%20-%20Fatima%2C%20Colombo%20-%20PR%2C%2083408-410!5e0!3m2!1spt-BR!2sbr!4v1709319529116!5m2!1spt-BR!2sbr"
          allowFullScreen={true}
          loading="lazy"
        />
      </Box>
    </Box>
  );
};

export default MapComponent;

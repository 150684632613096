import React from "react";
import backgroundImage from '../../assets/images/branco.jpg';

const Main = ({ children }: any) => {
  return (
    <main style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundAttachment: 'fixed',
      minHeight: '100vh'
    }}>
      {children}
    </main>
  );
};

export default Main;
